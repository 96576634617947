import React from 'react';
import styled from 'styled-components';

const HeaderContainer = styled.div`
  margin-bottom: 40px;
`;

const Name = styled.h1`
  font-size: 32px;
  font-weight: bold;
`;

const Title = styled.h2`
  font-size: 20px;
  font-weight: 400;
`;

interface HeaderProps {
    name: string | React.ReactNode;
    title: string | React.ReactNode;
}

const Header: React.FC<HeaderProps> = ({ name, title }) => {
    return (
        <HeaderContainer>
            <Name>{name}</Name>
            <Title>{title}</Title>
        </HeaderContainer>
    );
};

export default Header;

import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.div`
  margin-top: 40px;
`;

const Link = styled.a`
  color: #fff;
  text-decoration: none;
  margin-right: 15px;

  &:hover {
    text-decoration: underline;
  }
`;

interface FooterProps {
    links: { label: string; url: string }[];
}

const Footer: React.FC<FooterProps> = ({ links }) => {
    return (
        <FooterContainer>
            {links.map((link) => (
                <Link key={link.label} href={link.url} target="_blank" rel="noopener noreferrer">
                    {link.label}
                </Link>
            ))}
        </FooterContainer>
    );
};

export default Footer;

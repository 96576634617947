import React from 'react';
import styled from 'styled-components';

const SectionContainer = styled.div`
  margin-bottom: 30px;

  @media (min-width: 769px) {
    max-width: 40%;
  }
`;

const Title = styled.h3`
  font-size: 18px;
  font-weight: 600;
`;

const Content = styled.p`
  font-size: 16px;
  line-height: 1.6;
  white-space: pre-wrap; /* To preserve line breaks */
`;

interface SectionProps {
    title: string | React.ReactNode;
    content: string | React.ReactNode
}

const Section: React.FC<SectionProps> = ({ title, content }) => {
    return (
        <SectionContainer>
            <Title>{title}</Title>
            <Content>{content}</Content>
        </SectionContainer>
    );
};

export default Section;
